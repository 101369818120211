import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Reverse Hyperextensions & Reactive Box Jumps 4×10 each`}</p>
    <p>{`and SDHP’s 5×2`}</p>
    <p>{`then,`}</p>
    <p>{`50-Double Unders, Situps, 25-Deadlifts (185/125)`}</p>
    <p>{`40-Double Unders, Situps, 20-Deadlifts`}</p>
    <p>{`30-Double Unders, Situps, 15-Deadlifts`}</p>
    <p>{`20-Double Unders, Situps, 10-Deadlifts`}</p>
    <p>{`10-Double Unders, Situps, 5-Deadlifts`}</p>
    <p>{`for time.`}</p>
    <p><em parentName="p">{`*`}{`compare to 2/28/19`}</em></p>
    <p><strong parentName="p">{`*`}{`*`}{`Our annual Free Thanksgiving Day workout will be at 9:00am on
Thursday.  Partner WOD so bring a friend!  The gym will only be open for
this class.`}</strong></p>
    <p><strong parentName="p">{`*`}{`*`}{`*`}{`There will be no open gym on Friday but all open gym members are
welcome to come to our classes at no charge.  We will cancel the 6am &
6pm classes on Friday but the 10:30, 12:00, 4:00 & 5:00 classes are
available.`}</strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      